import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import "light-icons/dist/light-icon.css";

Vue.config.productionTip = false;

const options = {
  timeout: 5000,
  pauseOnHover: false,
  hideProgressBar: true,
};
Vue.use(Toast, options);

new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
