import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    domain: "https://elixir.cloudious.net/api",
    mediaDomain: "https://elixir.cloudious.net",
    token:
      "Bearer 82ab6aa53f046df15b31c8d47857cb484be137a056a8e0b4149b92f5de35515b6947fe698dbe6dbdd046f0046bf5d880682e9dbd93de4266f9f78bcd24009d6af6dd5b5fae3790a1623ccde1cb0365300c917df9fdfc6978f8fcb36f58ad0f4c17343c3610cbf86045bed6fe4aea344de5fbd8042a586db9fffdb545f3220c9f",
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
