<template>
  <div>
    <LvDialog
      header="Elixir App"
      v-model="displayBasic"
      :style="{ maxWidth: '900px', width: '100%' }"
    >
      <div class="app-links">
        <div>
          <video playsinline="" autoplay controls class="app-video">
            <source
              type="video/mp4"
              src="@/assets/video/elixir-app-tutorial.mp4"
            />
          </video>
        </div>
        <a
          href="https://play.google.com/store/apps/details?id=com.myskool.elixir"
          target="_blank"
        >
          <img src="@/assets/images/playstore.svg" width="135" alt="" />
        </a>
        <a
          href="https://apps.apple.com/pk/app/elixir-school/id6503348432"
          target="_blank"
        >
          <img src="@/assets/images/apple.svg" width="135" alt="" />
        </a>
      </div>
    </LvDialog>
    <div
      class="visible-phone page-block style-97855 clearfix"
      data-cid="414"
      id="content_414"
    >
      <div class="content mobilemenu">
        <div role="navigation" id="dl-menu" class="dl-menuwrapper mm">
          <ul class="mm-button-container">
            <li class="mm-buttons">
              <a
                class="dl-trigger mm-button"
                :class="{ 'hide-icon': menuOpen }"
                role="button"
                aria-label="Menu"
                tabindex="0"
                @click="menuOpen = true"
                ><span class="icon"></span><span class="label"></span
              ></a>
              <a
                class="mm-close"
                tabindex="0"
                :class="{ 'show-icon': menuOpen }"
                @click="menuOpen = false"
                >&times;</a
              >
            </li>
            <li>
              <div class="mm-title">
                <!-- <img src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/logo/elixir-mobile.png" alt="" /> -->
                <span></span>
              </div>
            </li>
            <li class="mm-buttons mm-login"></li>
          </ul>

          <ul class="dl-menu mm-container" :class="{ 'dl-menuopen': menuOpen }">
            <li class="mm-item">
              <a class="mm-text" href="/about">About us</a>
              <div></div>
            </li>
            <li class="mm-item">
              <a
                class="mm-text"
                href="https://web.myskool.app/inquiry-elixir"
                target="_blank"
                >Admission</a
              >
              <div></div>
            </li>
            <li class="mm-item">
              <a class="mm-text" href="#">Contact us</a>
              <div></div>
            </li>
            <li class="mm-item">
              <!-- <a
                class="mm-text"
                href="https://play.google.com/store/apps/details?id=com.myskool.pk"
                target="_blank"
                >Elixir App</a
              > -->
              <a class="mm-text" @click="displayBasic = true">Elixir App</a>
              <div></div>
            </li>
            <!-- <li class="mm-item">
                <a class="mm-text" href="#">Home</a>
                <div></div>
              </li> -->
            <!-- <li class="mm-item">
                <a
                  class="mm-text"
                  href="https://web.myskool.app/inquiry-elixir"
                  target="_blank"
                  >Admission</a
                >
                <div></div>
              </li>
              <li class="mm-item">
                <a class="mm-text" href="#">Explore Elixir</a>
                <div></div>
              </li>
              <li class="mm-item">
                <a class="mm-text" href="#">Career</a>
                <div></div>
              </li>
              <li class="mm-item">
                <a class="mm-text" href="mailto: officesupport@theelixirschools.com">Contact Us</a>
                <div></div>
              </li> -->
          </ul>
          <!-- <ul class="dl-menu mm-container">
              <li class="mm-item">
                <a class="mm-text" href="admission.html">Admission</a>
                <div tabindex="0">
                  <span class="mm-sub-ind">arrow</span>
                </div>
                <ul class="dl-submenu mm-container">
                  <li class="mm-item">
                    <a class="mm-text" href="admission/admission-team.html">Admission Team</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="admission/how-to-apply.html">How to Apply</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="admission/on-the-road-to-you.html">On The Road To You</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="admission/open-house.html">Open House</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="admission/request-information.html">Request Information</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="admission/scholarships-and-bursaries.html">Scholarships &amp; Bursaries</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="admission/take-a-tour.html">Take a Tour</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="admission/tuition-and-fees.html">Tuition &amp; Fees</a>
                    <div tabindex="0">
                      <span class="mm-sub-ind">arrow</span>
                    </div>
                    <ul class="dl-submenu mm-container">
                      <li class="mm-item">
                        <a class="mm-text" href="admission/tuition-and-fees/financial-guide.html">Financial Guide</a>
                        <div></div>
                      </li>
                    </ul>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="admission/why-a-school-for-boys.html">Why a School for Boys?</a>
                    <div></div>
                  </li>
                </ul>
              </li>
              <li class="mm-item">
                <a class="mm-text" href="academics.html">Academics</a>
                <div tabindex="0">
                  <span class="mm-sub-ind">arrow</span>
                </div>
                <ul class="dl-submenu mm-container">
                  <li class="mm-item">
                    <a class="mm-text" href="academics/heads-welcome.html">Head&#39;s Welcome</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="academics/advanced-placement.html">Advanced Placement</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="academics/leadership-programming.html">Leadership Programming</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="academics/summer-school.html">Summer School</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="https://ca.accessit.online/STA39/#!dashboard">Towers Library</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="academics/university-counselling.html">University Counselling</a>
                    <div></div>
                  </li>
                </ul>
              </li>
              <li class="mm-item">
                <a class="mm-text" href="arts.html">Arts</a>
                <div></div>
              </li>
              <li class="mm-item">
                <a class="mm-text" href="athletics.html">Athletics</a>
                <div></div>
              </li>
              <li class="mm-item">
                <a class="mm-text" href="boarding.html">Boarding</a>
                <div></div>
              </li>
              <li class="mm-item">
                <a class="mm-text" href="community.html">Community</a>
                <div tabindex="0">
                  <span class="mm-sub-ind">arrow</span>
                </div>
                <ul class="dl-submenu mm-container">
                  <li class="mm-item">
                    <a class="mm-text" href="community/old-boys.html">Old Boys</a>
                    <div tabindex="0">
                      <span class="mm-sub-ind">arrow</span>
                    </div>
                    <ul class="dl-submenu mm-container">
                      <li class="mm-item">
                        <a class="mm-text" href="community/old-boys/information-update-form.html"
                          >Information Update Form</a
                        >
                        <div></div>
                      </li>
                      <li class="mm-item">
                        <a class="mm-text" href="community/old-boys/request-a-transcript.html">Request a Transcript</a>
                        <div></div>
                      </li>
                      <li class="mm-item">
                        <a class="mm-text" href="community/old-boys/homecoming.html">Homecoming</a>
                        <div></div>
                      </li>
                      <li class="mm-item">
                        <a class="mm-text" href="community/old-boys/homecoming-reunion.html">Homecoming Reunion</a>
                        <div></div>
                      </li>
                    </ul>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="community/parents.html">Parents</a>
                    <div tabindex="0">
                      <span class="mm-sub-ind">arrow</span>
                    </div>
                    <ul class="dl-submenu mm-container">
                      <li class="mm-item">
                        <a class="mm-text" href="community/parents/andrean-boarding-opportunity.html"
                          >Andrean Boarding Opportunity</a
                        >
                        <div tabindex="0">
                          <span class="mm-sub-ind">arrow</span>
                        </div>
                        <ul class="dl-submenu mm-container">
                          <li class="mm-item">
                            <a class="mm-text" href="community/parents/andrean-boarding-opportunity/abo-calculator.html"
                              >ABO Calculator</a
                            >
                            <div></div>
                          </li>
                          <li class="mm-item">
                            <a
                              class="mm-text"
                              href="community/parents/andrean-boarding-opportunity/abo-enrolment-form.html"
                              >ABO Enrolment Form</a
                            >
                            <div></div>
                          </li>
                        </ul>
                      </li>
                      <li class="mm-item">
                        <a class="mm-text" href="https://sac.edsby.com/">Edsby</a>
                        <div></div>
                      </li>
                      <li class="mm-item">
                        <a class="mm-text" href="community/parents/gala.html">Gala</a>
                        <div></div>
                      </li>
                      <li class="mm-item">
                        <a class="mm-text" href="https://sac.myschoolapp.com/app#login">Parent Portal</a>
                        <div></div>
                      </li>
                      <li class="mm-item">
                        <a class="mm-text" href="community/parents/pay-my-tuition.html">Pay My Tuition</a>
                        <div></div>
                      </li>
                      <li class="mm-item">
                        <a class="mm-text" href="community/parents/school-dates.html">School Dates</a>
                        <div></div>
                      </li>
                    </ul>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="community/campus-shop.html">Campus Shop</a>
                    <div tabindex="0">
                      <span class="mm-sub-ind">arrow</span>
                    </div>
                    <ul class="dl-submenu mm-container">
                      <li class="mm-item">
                        <a class="mm-text" href="community/campus-shop/appointments.html">Appointments</a>
                        <div></div>
                      </li>
                    </ul>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="community/careers.html">Careers</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="community/facilities.html">Facilities</a>
                    <div tabindex="0">
                      <span class="mm-sub-ind">arrow</span>
                    </div>
                    <ul class="dl-submenu mm-container">
                      <li class="mm-item">
                        <a class="mm-text" href="community/facilities/arena-schedule.html">Arena Schedule</a>
                        <div></div>
                      </li>
                    </ul>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="community/history-and-archives.html">History &amp; Archives</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="community/land-acknowledgement.html">Land Acknowledgement</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="community/photo-gallery.html">Photo Gallery</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="community/school-leadership.html">School Leadership</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="community/summer-camp.html">Summer Camp</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="community/the-andrean.html">The Andrean</a>
                    <div></div>
                  </li>
                </ul>
              </li>
              <li class="mm-menu-separator"></li>
              <li class="mm-item">
                <a class="mm-text" href="community/careers.html">Careers</a>
                <div></div>
              </li>
              <li class="mm-item">
                <a class="mm-text" href="community/parents.html">Parents</a>
                <div></div>
              </li>
              <li class="mm-item">
                <a class="mm-text" href="sas.html">SAS</a>
                <div></div>
              </li>
              <li class="mm-item">
                <a class="mm-text" href="support-sac.html">Support SAC</a>
                <div tabindex="0">
                  <span class="mm-sub-ind">arrow</span>
                </div>
                <ul class="dl-submenu mm-container">
                  <li class="mm-item">
                    <a class="mm-text" href="support-sac/donate.html">Donate</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="support-sac/current-initiatives.html">Current Initiatives</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="support-sac/donor-impact-report.html">Donor Impact Report</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="support-sac/tribute-gifts.html">Tribute Gifts</a>
                    <div></div>
                  </li>
                  <li class="mm-item">
                    <a class="mm-text" href="support-sac/legacy-giving.html">Legacy Giving</a>
                    <div></div>
                  </li>
                </ul>
              </li>
              <li class="mm-menu-separator"></li>
              <li class="mm-item">
                <a class="mm-text" href="accessibility.html">Accessibility</a>
                <div></div>
              </li>
              <li class="mm-item">
                <a class="mm-text" href="contact-us.html">Contact Us</a>
                <div></div>
              </li>
              <li class="mm-item">
                <a class="mm-text" href="privacy.html">Privacy</a>
                <div></div>
              </li>
              <li class="mm-menu-separator"></li>
            </ul> -->
        </div>
      </div>
    </div>

    <div class="first-col layout-col span6 col">
      <div
        class="layout-block style-103632 clearfix"
        data-cid="129"
        id="content_177511"
      >
        <div class="content externalvideo">
          <div class="content-wrap">
            <header class="viewport-header">
              <a href="/">
                <img
                  src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/logo/elixir.png"
                  alt="The Elixir School"
                  class="logo-main"
                />
              </a>
            </header>
          </div>
        </div>
      </div>
    </div>

    <div class="last-col layout-col span6 col">
      <div
        class="hidden-phone layout-block style-103614 clearfix"
        data-cid="389"
        id="content_177513"
      >
        <div class="content menu">
          <nav
            class="nav-menu menu-animate-true menu-action-hover menu-type-horizontal-flat menu-direction-down"
          >
            <ul class="menu-container level1">
              <li
                class="menu-item menu-item-111827 l1-item level-1 my-dropdown"
              >
                <a href="/about">About us</a>
                <!-- <ul class="my-dropdown-list">
                              <li><a href="#">Mission Statement</a></li>
                              <li><a href="#">Vision Statement</a></li>
                            </ul> -->
              </li>
              <li class="menu-item menu-item-111822 l1-item level-1">
                <a href="https://web.myskool.app/inquiry-elixir" target="_blank"
                  >Admission</a
                >
              </li>
              <li class="menu-item menu-item-112123 l1-item level-1">
                <a href="#">Contact us</a>
              </li>
              <li class="menu-item menu-item-111463 l1-item level-1">
                <!-- <a
                  href="https://play.google.com/store/apps/details?id=com.myskool.pk"
                  target="_blank"
                  >Elixir App</a
                > -->
                <a href="#" @click="displayBasic = true">Elixir App</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LvDialog from "lightvue/dialog";

export default {
  name: "Header",
  components: {
    LvDialog,
  },
  data() {
    return {
      displayBasic: false,
      menuOpen: false,
    };
  },
};
</script>

<style scoped>
[class*="span"] {
  min-height: unset !important;
}
</style>
