import store from "@/store/index";

export const convertSpecialChars = (val) => {
  if (!val) return "";
  return val
    .replace(/\r\n|\n/g, "<br>")
    .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");
};

export const smallMedia = (att) => {
  if (att.formats && att.formats.medium) {
    return `${store.state.mediaDomain}${att.formats.medium.url}`;
  }
  return `${store.state.mediaDomain}${att.url}`;
};
