<template>
  <div class="custom-loader-wrapper">
    <h1>Page Not Found 🕵🏻‍♀️</h1>
    <p>
      Oops! 😖 The page you're looking for does not exist or have been
      temporarily unavailable.
    </p>
  </div>
</template>
<script>
export default {
  name: "NotFound",
};
</script>
<style></style>
