import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from "../views/HomeView.vue";
// import About from "../views/About.vue";
import HomeNew from "@/views/HomeNew.vue";
import AboutNew from "@/views/AboutNew.vue";
import NotFound from "@/views/NotFound.vue";
import StudentLife from "@/views/StudentLife.vue";
import Parent from "@/views/Parent.vue";
import Contact from "@/views/Contact.vue";
import Calendar from "@/views/Calendar.vue";
import LiveMeeting from "@/views/LiveMeeting.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeNew,
  },
  {
    path: "/about",
    name: "about",
    component: AboutNew,
  },
  {
    path: "/student-life",
    name: "student-life",
    component: StudentLife,
  },
  {
    path: "/parent",
    name: "parent",
    component: Parent,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/calendar",
    name: "calendar",
    component: Calendar,
  },
  {
    path: "/live-meeting",
    name: "live-meeting",
    component: LiveMeeting,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
