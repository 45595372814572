<template>
  <div class="container meeting-wrapper">
    <iframe
      class="adjust-wrapper meeting-iframe"
      src="https://p2p.mirotalk.com/join/40f02fa3-6030-415b-afc8-468109511641"
      allow="microphone; camera"
    ></iframe>
  </div>
</template>
<script>
export default {
  name: "LiveMeeting",
  data() {
    return {};
  },
};
</script>
<style></style>
