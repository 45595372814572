<template>
  <div>
    <div v-if="dataLoading" class="custom-loader-wrapper">
      <div class="custom-loader"></div>
    </div>
    <template v-else-if="pageData">
      <div
        v-if="pageData.Banner"
        class="full-width style-105126"
        :style="{
          'background-image': `linear-gradient(to top, transparent, rgba(0, 0, 0, 0.5)), url(${smallMedia(
            pageData.Banner.Image.data.attributes
          )})`,
        }"
      ></div>

      <div
        v-if="pageData.ParentsResource"
        class="style-105032 region layout-region indi-section single-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <span class="heading-black">
                                {{ pageData.ParentsResource.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.ParentsResource.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="my-content"
                        v-html="
                          convertSpecialChars(pageData.ParentsResource.Details)
                        "
                      ></div>
                    </div>
                  </div>
                  <div
                    v-if="pageData.ParentsResource.Items.length > 0"
                    class="row physical-wrapper"
                  >
                    <div
                      v-for="item in pageData.ParentsResource.Items"
                      :key="item.id"
                      class="first-col page-col span12 col"
                      data-aos="flip-right"
                      data-aos-duration="1200"
                    >
                      <div class="">
                        <h2 class="my-heading">{{ item.Heading }}</h2>
                        <div
                          class="my-content"
                          v-html="convertSpecialChars(item.Details)"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.PTA"
        class="style-105032 region layout-region indi-section single-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row" style="margin: 0">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <span class="heading-black">
                                {{ pageData.PTA.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.PTA.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div
                      class="first-col style-103700 page-col span12 col indi-images-wrapper single-image-wrapper"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      :class="{ 'image-right': pageData.PTA.Side == 'Right' }"
                    >
                      <div class="">
                        <img
                          :src="smallMedia(pageData.PTA.Image.data.attributes)"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div
                      class="first-col style-103700 page-col span12 col single-content-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <div
                              class="my-content"
                              v-html="convertSpecialChars(pageData.PTA.Details)"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.Seminar"
        class="style-105032 region layout-region indi-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <span class="heading-black">
                                {{ pageData.Seminar.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.Seminar.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div class="first-col page-col col">
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content">
                          <div class="content-wrap">
                            <div
                              class="my-content"
                              v-html="
                                convertSpecialChars(pageData.Seminar.Details)
                              "
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.Communication"
        class="style-105032 region layout-region indi-section single-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row" style="margin: 0">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <span class="heading-black">
                                {{ pageData.Communication.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.Communication.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div
                      class="first-col style-103700 page-col span12 col indi-images-wrapper single-image-wrapper"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      :class="{
                        'image-right': pageData.Communication.Side == 'Right',
                      }"
                    >
                      <div class="">
                        <img
                          :src="
                            smallMedia(
                              pageData.Communication.Image.data.attributes
                            )
                          "
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div
                      class="first-col style-103700 page-col span12 col single-content-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <div
                              class="my-content"
                              v-html="
                                convertSpecialChars(
                                  pageData.Communication.Details
                                )
                              "
                            ></div>
                            <a
                              class="button-link button-primary button-small m-top"
                              :href="getRoute(pageData.Communication.Button)"
                              :target="
                                pageData.Communication.Button.Redirect ==
                                  'Link' && pageData.Communication.Button.Link
                                  ? '_blank'
                                  : '_self'
                              "
                              >{{ pageData.Communication.Button.Title }}</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="custom-loader-wrapper">
      <h1>Page Not Found 🕵🏻‍♀️</h1>
      <p>
        Oops! 😖 The page you're looking for does not exist or have been
        temporarily unavailable.
      </p>
    </div>
  </div>
</template>
<script>
import { convertSpecialChars, smallMedia } from "@/utils/utils";
import axios from "axios";

export default {
  name: "Parent",
  data() {
    return {
      pageData: null,
      dataLoading: false,
    };
  },
  created() {
    this.LoadData();
  },
  methods: {
    convertSpecialChars,
    smallMedia,

    getRoute(item) {
      if (item.Redirect == "Page" && item.Page) {
        return item.Page == "Home" ? "/" : `/${item.Page.toLowerCase()}`;
      } else if (item.Redirect == "Link" && item.Link) {
        return item.Link;
      } else {
        return "#";
      }
    },
    LoadData() {
      this.dataLoading = true;
      let config = {
        method: "get",
        url: `${this.$store.state.domain}/parents?populate=deep`,
        headers: {
          Authorization: this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.data.length > 0) {
            this.pageData = res.data.data[0].attributes;
          } else this.pageData = null;
        })
        .catch((err) => console.log(err))
        .finally(() => (this.dataLoading = false));
    },
  },
};
</script>
<style></style>
