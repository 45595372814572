<template>
  <div>
    <div v-if="dataLoading" class="custom-loader-wrapper">
      <div class="custom-loader"></div>
    </div>
    <template v-else-if="pageData">
      <div
        v-if="pageData.Banner"
        class="full-width style-105126"
        :style="{
          'background-image': `linear-gradient(to top, transparent, rgba(0, 0, 0, 0.5)), url(${smallMedia(
            pageData.Banner.Image.data.attributes
          )})`,
        }"
      ></div>

      <div
        v-if="pageData.ExtraCurricular"
        class="style-105032 region layout-region indi-section single-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <span class="heading-black">
                                {{ pageData.ExtraCurricular.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.ExtraCurricular.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="my-content"
                        v-html="
                          convertSpecialChars(pageData.ExtraCurricular.Details)
                        "
                      ></div>
                    </div>
                  </div>
                  <div
                    v-if="pageData.ExtraCurricular.Items.length > 0"
                    class="row physical-wrapper"
                  >
                    <div
                      v-for="item in pageData.ExtraCurricular.Items"
                      :key="item.id"
                      class="first-col page-col span12 col"
                      data-aos="flip-right"
                      data-aos-duration="1200"
                    >
                      <div class="">
                        <h2 class="my-heading">{{ item.Heading }}</h2>
                        <div
                          class="my-content"
                          v-html="convertSpecialChars(item.Details)"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.ClubsAndOrganization"
        class="style-105032 region layout-region indi-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <span class="heading-black">
                                {{ pageData.ClubsAndOrganization.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.ClubsAndOrganization.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div class="first-col page-col col">
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content">
                          <div class="content-wrap">
                            <div
                              class="my-content"
                              v-html="
                                convertSpecialChars(
                                  pageData.ClubsAndOrganization.Details
                                )
                              "
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.ClubDetails.length > 0"
        class="style-105032 region layout-region indi-section single-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div
                    v-for="item in pageData.ClubDetails"
                    :key="item.id"
                    class="page-row row"
                  >
                    <div
                      class="first-col style-103700 page-col span12 col indi-images-wrapper single-image-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                      :class="{ 'image-right': item.Side == 'Right' }"
                    >
                      <div class="">
                        <img
                          :src="smallMedia(item.Image.data.attributes)"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div
                      class="first-col style-103700 page-col span12 col single-content-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <h2 class="my-heading">{{ item.Heading }}</h2>
                            <div
                              class="my-content"
                              v-html="convertSpecialChars(item.Details)"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.SportsTeam"
        class="style-105032 region layout-region indi-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <span class="heading-black">
                                {{ pageData.SportsTeam.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.SportsTeam.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div class="first-col page-col col">
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content">
                          <div class="content-wrap">
                            <div
                              class="my-content"
                              v-html="
                                convertSpecialChars(pageData.SportsTeam.Details)
                              "
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.SportsDetails.length > 0"
        class="style-105032 region layout-region indi-section single-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div
                    v-for="item in pageData.SportsDetails"
                    :key="item.id"
                    class="page-row row"
                  >
                    <div
                      class="first-col style-103700 page-col span12 col indi-images-wrapper single-image-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                      :class="{ 'image-right': item.Side == 'Right' }"
                    >
                      <div class="">
                        <img
                          :src="smallMedia(item.Image.data.attributes)"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div
                      class="first-col style-103700 page-col span12 col single-content-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <h2 class="my-heading">{{ item.Heading }}</h2>
                            <div
                              class="my-content"
                              v-html="convertSpecialChars(item.Details)"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.StudentLeadership"
        class="style-105032 region layout-region indi-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <span class="heading-black">
                                {{ pageData.StudentLeadership.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.StudentLeadership.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div class="first-col page-col col">
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content">
                          <div class="content-wrap">
                            <div
                              class="my-content"
                              v-html="
                                convertSpecialChars(
                                  pageData.StudentLeadership.Details
                                )
                              "
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.StudentHandbook"
        class="style-105032 region layout-region indi-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <span class="heading-black">
                                {{ pageData.StudentHandbook.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.StudentHandbook.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div class="first-col page-col col">
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content">
                          <div class="content-wrap">
                            <div
                              class="my-content"
                              v-html="
                                convertSpecialChars(
                                  pageData.StudentHandbook.Details
                                )
                              "
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="custom-loader-wrapper">
      <h1>Page Not Found 🕵🏻‍♀️</h1>
      <p>
        Oops! 😖 The page you're looking for does not exist or have been
        temporarily unavailable.
      </p>
    </div>
  </div>
</template>
<script>
import { convertSpecialChars, smallMedia } from "@/utils/utils";
import axios from "axios";

export default {
  name: "StudentLife",
  data() {
    return {
      pageData: null,
      dataLoading: false,
    };
  },
  created() {
    this.LoadData();
  },
  methods: {
    convertSpecialChars,
    smallMedia,

    LoadData() {
      this.dataLoading = true;
      let config = {
        method: "get",
        url: `${this.$store.state.domain}/student-lives?populate=deep`,
        headers: {
          Authorization: this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.data.length > 0) {
            this.pageData = res.data.data[0].attributes;
          } else this.pageData = null;
        })
        .catch((err) => console.log(err))
        .finally(() => (this.dataLoading = false));
    },
  },
};
</script>
<style></style>
