<template>
  <div
    v-if="footerData"
    class="style-103641 region layout-region"
    id="layout_region_8"
  >
    <div class="full-width">
      <div class="layout-row row-fluid">
        <div class="first-col layout-col span5 col">
          <div class="layout-block clearfix" data-cid="395" id="content_179873">
            <div class="content logo">
              <div class="content-wrap">
                <img
                  :src="smallMedia(footerData.Logo.data.attributes)"
                  alt="Elixir"
                  class="logo-footer"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="layout-col span14 col">
          <div
            v-if="footerData.Details"
            class="layout-block style-104981 clearfix"
            data-cid="1"
            id="content_180140"
          >
            <div class="content text">
              <div class="content-wrap">
                <div
                  v-html="convertSpecialChars(footerData.Details)"
                  style="text-align: center"
                ></div>
              </div>
            </div>
          </div>

          <div
            v-if="footerData.PageLinks.length > 0"
            class="layout-block style-104849 clearfix"
            data-cid="389"
            id="content_179874"
          >
            <div class="content menu">
              <nav
                class="nav-menu menu-animate-true menu-action-hover menu-type-horizontal-flat menu-direction-down"
              >
                <ul
                  v-for="item in footerData.PageLinks"
                  :key="item.id"
                  class="menu-container level1"
                >
                  <li class="menu-item menu-item-112197 l1-item level-1">
                    <a
                      :href="
                        item.Redirect == 'None' ? '#' : `/${item.Redirect}`
                      "
                    >
                      {{ item.Title }}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div
          v-if="footerData.SocialLinks.length > 0"
          class="last-col layout-col span5 col"
        >
          <div
            class="layout-block style-104557 clearfix"
            data-cid="2"
            id="content_179875"
          >
            <div class="content links">
              <div class="content-wrap">
                <ul class="no-bullets">
                  <li
                    v-for="item in footerData.SocialLinks"
                    :key="item.id"
                    class="group"
                  >
                    <a
                      :href="item.Link"
                      :target="item.Link == '#' ? '_self' : '_blank'"
                      class="custom-link-photo style-no-select links-imglink"
                    >
                      <img
                        :src="smallMedia(item.Icon.data.attributes)"
                        class="link-image"
                        alt="YouTube"
                        loading="lazy"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { convertSpecialChars, smallMedia } from "@/utils/utils";
import axios from "axios";

export default {
  name: "FooterNew",
  data() {
    return {
      footerData: null,
      dataLoading: false,
    };
  },
  created() {
    this.LoadData();
  },
  methods: {
    convertSpecialChars,
    smallMedia,
    LoadData() {
      this.dataLoading = true;
      let config = {
        method: "get",
        url: `${this.$store.state.domain}/footers?populate=deep`,
        headers: {
          Authorization: this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.data.length > 0) {
            this.footerData = res.data.data[0].attributes.Footer;
          } else this.footerData = null;
        })
        .catch((err) => console.log(err))
        .finally(() => (this.dataLoading = false));
    },
  },
};
</script>
<style></style>
