<template>
  <div class="style-103641 region layout-region" id="layout_region_8">
    <div class="full-width">
      <div class="layout-row row-fluid">
        <div class="first-col layout-col span5 col">
          <div class="layout-block clearfix" data-cid="395" id="content_179873">
            <div class="content logo">
              <div class="content-wrap">
                <!-- <img src='bbk12e1-cdn.myschoolcdn.com/ftpimages/842/logo/SAC-Logo.png' alt='Elixir' s=""
                    college'=""> -->
                <img
                  src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/logo/elixir.png"
                  alt="Elixir"
                  class="logo-footer"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="layout-col span14 col">
          <div
            class="layout-block style-104981 clearfix"
            data-cid="1"
            id="content_180140"
          >
            <div class="content text">
              <div class="content-wrap">
                <div style="text-align: center">
                  <strong
                    >We are committed to building a sense of belonging in our
                    community.</strong
                  >
                </div>
                <div style="text-align: center">
                  By dedicating ourselves to creating a diverse, inclusive, and
                  equitable environment, we will foster and accept the unique
                  individuality of each of our citizens, ensuring all members
                  feel valued and respected.
                </div>
              </div>
            </div>
          </div>
          <div
            class="layout-block style-103640 clearfix"
            data-cid="1"
            id="content_177566"
          >
            <div class="content text">
              <div class="content-wrap">
                "Dedicated to the development of the complete man, the
                well-rounded citizen." | &#169; 2024 The Elixir School. All
                Rights Reserved. officesupport@theelixirschools.com Street 1,
                Block G , North Nazimabad (Near North Nazimabad)
              </div>
            </div>
          </div>
          <div
            class="hidden-phone layout-block style-104849 clearfix"
            data-cid="389"
            id="content_179874"
          >
            <div class="content menu">
              <nav
                class="nav-menu menu-animate-true menu-action-hover menu-type-horizontal-flat menu-direction-down"
              >
                <ul class="menu-container level1">
                  <li class="menu-item menu-item-112197 l1-item level-1">
                    <a href="#"> Accessibility </a>
                  </li>
                  <li class="menu-item menu-item-111539 l1-item level-1">
                    <a href="#"> Contact Us </a>
                  </li>
                  <li class="menu-item menu-item-112196 l1-item level-1">
                    <a href="#"> Privacy </a>
                  </li>
                  <!-- <li class="menu-item menu-item-112197 l1-item level-1">
                        <a href="accessibility.html"> Accessibility </a>
                      </li>
                      <li class="menu-item menu-item-111539 l1-item level-1">
                        <a href="contact-us.html"> Contact Us </a>
                      </li>
                      <li class="menu-item menu-item-112196 l1-item level-1">
                        <a href="privacy.html"> Privacy </a>
                      </li> -->
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div class="last-col layout-col span5 col">
          <div
            class="layout-block style-104557 clearfix"
            data-cid="2"
            id="content_179875"
          >
            <div class="content links">
              <div class="content-wrap">
                <ul class="no-bullets">
                  <li class="group">
                    <a
                      href="#"
                      class="custom-link-photo style-no-select links-imglink"
                    >
                      <img
                        src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/link/large_link425366_151192.png"
                        class="link-image"
                        alt="YouTube"
                      />
                    </a>
                  </li>
                  <li class="group">
                    <a
                      href="https://www.facebook.com/ElixirSchoolKarachi"
                      target="_blank"
                      class="custom-link-photo style-no-select links-imglink"
                    >
                      <img
                        src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/link/large_link425365_151190.png"
                        class="link-image"
                        alt="Facebook"
                      />
                    </a>
                  </li>
                  <li class="group">
                    <a
                      href="https://www.instagram.com/the_elixir_school/"
                      target="_blank"
                      class="custom-link-photo style-no-select links-imglink"
                    >
                      <img
                        src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/link/large_link476434_151191.png"
                        class="link-image"
                        alt="Instagram"
                      />
                    </a>
                  </li>
                  <li class="group">
                    <a
                      href="#"
                      class="custom-link-photo style-no-select links-imglink"
                    >
                      <img
                        src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/link/large_link425374_152401.png"
                        class="link-image"
                        alt="Twitter"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
